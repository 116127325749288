.custom-link {
    color: black;
    text-decoration: underline;
}

.custom-calendar td {
    border-left: dashed 1px #d9d9d9;
    border-right: dashed 1px #d9d9d9;
}

.signin .ant-card-head {
    padding-left: 0;
}

.star-container {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-right: 4px;
}

.number-overlay {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 6px; /* Размер текста для цифры */
    font-weight: bold;
}